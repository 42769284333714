// src/components/Bento.js
import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import "../styles/Bento.css";

const images = [
 "https://i.imgur.com/CiOxCd2.jpeg" ,
   "https://i.imgur.com/UCi17ES.png" ,
   "https://i.imgur.com/tytWHWo.jpeg" ,
   "https://i.imgur.com/VLu91Hj.jpg" ,
   "https://i.imgur.com/sx5R3o2.jpeg" ,
   "https://i.imgur.com/SV2bw91.jpeg" ,
   "https://i.imgur.com/DADLPl5.jpeg" ,
   "https://i.imgur.com/P15WFop.jpeg" ,
  //  "" 
];
   
const Bento = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="grid-container">
      <div className="grid-item item1" onClick={() => images[0] && handleOpen(0)} style={{ cursor: images[0] ? 'pointer' : 'default' }}>
        <img src={images[0]} alt="" />
      </div>
      <div className="grid-item item2" onClick={() => images[1] && handleOpen(1)} style={{ cursor: images[1] ? 'pointer' : 'default' }}>
        <img src={images[1]} alt="" />
      </div>
      <div className="grid-item item3" onClick={() => images[2] && handleOpen(2)} style={{ cursor: images[2] ? 'pointer' : 'default' }}>
        <img src={images[2]} alt="" />
      </div>
      <div className="grid-item item4" onClick={() => images[3] && handleOpen(3)} style={{ cursor: images[3] ? 'pointer' : 'default' }}>
        <img src={images[3]} alt="" />
      </div>
      <div className="grid-item item5" onClick={() => images[4] && handleOpen(4)} style={{ cursor: images[4] ? 'pointer' : 'default' }}>
        <img src={images[4]} alt="" />
      </div>
      <div className="grid-item item6" onClick={() => images[5] && handleOpen(5)} style={{ cursor: images[5] ? 'pointer' : 'default' }}>
        <img src={images[5]} alt="" />
      </div>
      <div className="grid-item item7" onClick={() => images[6] && handleOpen(6)} style={{ cursor: images[6] ? 'pointer' : 'default' }}>
        <img src={images[6]} alt="" />
      </div>
      <div className="grid-item item8" onClick={() => images[7] && handleOpen(7)} style={{ cursor: images[7] ? 'pointer' : 'default' }}>
        <img src={images[7]} alt="" />
      </div>
      {/* <div className="grid-item item9" onClick={() => images[8] && handleOpen(8)} style={{ cursor: images[8] ? 'pointer' : 'default' }}>
        <img src={images[8]} alt="" />
      </div> */}
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.filter(Boolean).map((src) => ({ src }))}
          index={photoIndex}
          onPrev={() => setPhotoIndex((photoIndex + images.filter(Boolean).length - 1) % images.filter(Boolean).length)}
          onNext={() => setPhotoIndex((photoIndex + 1) % images.filter(Boolean).length)}
        />
      )}
    </div>
  );
};

export default Bento;
